@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root{
  --light-cyan: #c9e4e7ff;
  --platinum: #e7e7e7ff;
  --dim-gray: #746f72ff;
  --ut-orange: #ff8811ff;
  --orange-pantone: #ff5400ff;
}
*{
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #000;
}

body{
  background-color: var(--platinum);
  padding: 0;
  margin: 0;
}
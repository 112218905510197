.pageTitle{
    width: 100%;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    gap: 10px;
    font-weight: 900;
    max-width: 1024px;
    margin: 0 auto;
}

.pageTitle button {
    font-size: 14px;
    border: none;
    outline: none;
    color: #000;
    padding: 10px;
    background-color: inherit;
    font-family: inherit;
    margin: 0; 
    background-color: var(--platinum);
    border: 1px solid var(--dim-gray);
  }
  
  .pageTitle button:hover {
    background-color: var(--orange-pantone);
    border: 1px solid var(--orange-pantone);
  }

  .pageContent{
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1024px;
    margin: 0 auto
  }
  .collum{
    flex-direction: column;
  }
  .pageChart{
    width: 100%;
    max-width: 1024px;
  }

  .subtitle{
    font-size: 15px;
  }

  .contentLine, .contentLine-3{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
  }

  .contentLine2{
    width: 100%;
    max-width: 1024px;
    background-color: var(--dim-gray);
    font-size: 19px; 
    padding: 10px; color: #fff;
    text-align: center;
    font-weight: 700;
    margin: 60px 0 20px 0;
  }

  .contentLine-3{
    gap: 15px;
  }

  .bloco{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .linesTitulo, .linesTitulo2{
    width: 100%;
    text-align: center;
    font-weight: 700;
  }

  .linesTitulo{font-size: 16px;}



  .lines{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #000 1px solid;
  }

  .lines-itemL, .lines-itemR {
    flex:1;
    font-size: 15px;

  }

  .lines-itemL{
    text-align: right;
    padding-right: 3px;
  }
  .lines-itemR{
    text-align: left;
    padding-left: 3px;
  }

  .custosLine{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }

  .custoContent, .custoContent0, .custoContentB{
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:1px solid #000;
    padding: 5px;
  }
  .custoContent, .custoContentB{
    flex:2;
  }
  .custoContentB{
    padding: 0;
  }
  .custoContent0{
    flex:1;

  }

  .custoContent span, .custoContent0 span{
    font-size: 12px;
  }
  .custoContent p, .custoContent0 p{
    font-size: 16px;
  }

  .custoContentB button{
    border: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: var(--platinum);
  }
  .custoContentB button:hover{
    background-color: var(--ut-orange) ;
  }

  .custoUp{
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .custoForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .custoFormLine{
    width: 100%;
    display: flex;
    gap: 5px;
  }

  .custoFormLine-text{
    flex:1;
    text-align: right;
  }

  .custoFormLine input{
    flex:1;
    justify-content: stretch;
  }

  .custoFormLine input[type=submit], .popContainer button{
    background-color: var(--platinum);
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5px;
    border: 1px solid #000;
  }

  .custoFormLine input[type=submit]:hover, .popContainer button:hover{
    background-color: var(--orange-pantone);
  }

  .popUp{
    position: absolute;
    z-index: 999;
    background-color: var(--platinum) ;
    top: 0;bottom: 0;left: 0;right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popContainer{
    width: 100%;
    max-width: 400px;
  }

  .selecionar{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
  }

  .selecionar select{
    max-width: 150px;
  }

  

  .tipo{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .linesClientes{
    width: 70%;
    display: flex;
    margin: 5px auto;
    gap: 5px;
  }

  .lines-itemC{
    flex:1;
    font-size: 17px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid #000;
  }